$(document).ready(function () {

	$('[data-toggle="datepicker"]').datepicker({
		autoPick: true
    });

    //landings pagina?
    if($('.landingspage').length > 0) {
		fadeImages();
		setInterval(function () {
			fadeImages();
		}, 3000);

		function fadeImages() {
			var elem = $(".fade-images");
			var items = elem.find("img.bottom");
			var number = items.length;
			var random = Math.floor((Math.random() * number));

			items.eq(random).toggleClass("bottom__hide");
		}

    //default page
    } else {
		// responsiveNav();
		navigation();
		slider();
		gallery();
		handleTable();
		handleFigure();
		scrollHandler();
		dotdotdot();

		$("footer").stickyFooter();

		$('#productTab a').click(function (e) {
			e.preventDefault()
			$(this).tab('show')
		});
    }



    function navigation() {

		// Mobile devices navigation
		$("#main-menu").mmenu({
			"extensions": [
				"pagedim-black"
			],
			navbar: {
				title: "Maison de la Bonne Vie"
			},
			offCanvas: {
				pageSelector: "#page-wrapper",
				position:"right"
			},
			"navbars": [
				{
					"position": "bottom",
					"content": [
						"<a href='https://www.facebook.com/maisondelabonnevie'><span class='icon-facebook'></span></a>",
						"<a href='https://twitter.com/MBV27496822'><span class='icon-twitter'></span></a>",
						"<a href='https://fr.pinterest.com/MBV12600/'><span class='icon-pinterest'></span></a>",
						"<a href='https://www.instagram.com/Maison.de.la.Bonne.Vie/'><span class='icon-instagram'></span></a>",
					]
				}
			]
		}, {
			clone: true
		});

        //Desktop hover
        $("#main-menu li").hover( function(){
            $(this).addClass('open');
        }, function(){
            $(this).removeClass('open');
        });

        //add link to the homepage
		$("#mm-main-menu").find( ".mm-panel:first .mm-navbar a" ).attr('href', '/')

        $("#language").mmenu({
            autoHeight: true,
            navbar: {
                title: "Language select"
            },
            offCanvas: {
                pageSelector: "#page-wrapper",
                position:"top",
                zposition: "front"
            }
        }, {
            clone: true

        });

        // Smooth contact button position on opening menu
        $('.hamburger-container .menu').click(function() {
            $('.contact-button').css('position', 'absolute');
        });

        var api = $('#mm-main-menu').data('mmenu');

        api.bind('closed', function() {
            $('.contact-button').css('position', 'fixed');
        });
    }

    function slider() {
        var owlImage = $("#owl-image-slider");
        owlImage.owlCarousel({
            items: 1,
            navigation: false,
            mouseDrag: false,
            loop: false,
            slideSpeed: 300,
            dots: false,
            paginationSpeed: 300,
            animateOut: 'fadeOut',
            autoplay: true,
            autoplayTimeout: 5000,
            lazyLoad: true,
            lazyContent: true,

            onLoadedLazy: function () {
                $('body').addClass('show-page');
            },
            responsive : {
                768 : {
                    autoplay: false,
                    onInitialized: function (event) {
                        var items = event.item.count;
                        var i = 1;
                        var interval = setInterval(function () {
                                if (i == items) i = 0;

                                owlImage.trigger("to.owl.carousel", [i, 300, true]);
                                i++;
                            },
                            7000);
                    }
                }
            }
        });

        var owlText = $("#owl-text-slider");
        owlText.owlCarousel({
            items: 1,
            navigation: false,
            mouseDrag: false,
            loop: false,
            slideSpeed: 300,
            dots: false,
            paginationSpeed: 300,
            animateOut: 'fadeOut',
            autoplay: true,
            autoplayTimeout: 7000,
            responsive : {
                768 : {
                    autoplay: false,
                    items: 3
                }
            },

            onLoadedLazy: function () {
                $('body').addClass('show-page');
            }
        });

        $('#owl-text-slider .owl-item:first-child').addClass("focus");
        owlImage.on('changed.owl.carousel', function(event) {
            var item = event.item.index +1;

            $('#owl-text-slider .owl-item').removeClass("focus");
            $('#owl-text-slider .owl-item:nth-child(' + item + ')').addClass("focus");
        });
        owlText.on('changed.owl.carousel', function(event) {
            var item = event.item.index;

            owlImage.trigger("to.owl.carousel", [item, 300, true]);
        });

    }

    function dotdotdot() {
        $("#wrapper").dotdotdot();
    }

    function gallery() {

        $('.gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            removalDelay: 500,
            callbacks: {
                beforeOpen: function() {
                    // just a hack that adds mfp-anim class to markup
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            closeOnContentClick: true,
            midClick: true,
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $('.gallery').find('a').length;

        if(galleryLength == 1) {
            $('.gallery-wrapper').addClass('one-image');
        } else if(galleryLength == 2) {
            $('.gallery-wrapper').addClass('two-image');
        } else if(galleryLength == 3) {
            $('.gallery-wrapper').addClass('three-image');
        } else if(galleryLength >= 4) {
            $('.gallery-wrapper').addClass('more');
        }

        //// Convert img to bg img
        $(".bg-img").each(function(i, elem) {
            var img = $(elem);
            var classes = img.attr('class');
            var div = $("<div />").addClass(classes).css({
                background: "url(" + img.attr("src") + ")"
            });
            img.replaceWith(div);
        });
    }

    function handleTable() {
        $('.page-content table').addClass('table table-responsive');
    }

    function handleFigure() {
        $('.page-content img').each(function () {
            var alt = $(this).attr('alt');
            $(this)
            //.unwrap()
                .removeAttr('style')
                .addClass('img-responsive')
                .wrap('<figure></figure>');
            if (alt) {
                $(this).after('<figcaption><p>' + alt + '</p></figcaption>');
            }
        });
    }
});



function matchHeight() {
    $(function() {
        if( $(window).width() > 750 ) {

            $('.equal-1').matchHeight(
                {
                    byRow: true,
                    target: $('.reference-1'),
                    remove: false
                }
            );
            $('.equal-2').matchHeight(
                {
                    byRow: true,
                    target: $('.reference-2'),
                    remove: false
                }
            );
            $('.equal-3').matchHeight(
                {
                    byRow: true,
                    target: $('.reference-3'),
                    remove: false
                }
            );
            $('.equal-4').matchHeight(
                {
                    byRow: true,
                    target: $('.reference-4'),
                    remove: false
                }
            );
            $('.equal-5').matchHeight(
                {
                    byRow: true,
                    target: $('.reference-5'),
                    remove: false
                }
            );
            $('.column').matchHeight(
                {
                    byRow: true,
                    target: $('.reference'),
                    remove: false
                }
            );
            $('.column p').matchHeight(
                {
                    byRow: true,
                    target: $('.reference'),
                    remove: false
                }
            );
            $('#products .equalHeight').matchHeight();
            $('#products .item').matchHeight();
            $('.equal-blog .description p').matchHeight();

            $(".blog-item-homepage").each(function() {
                var blogItemHeight = $(this).outerHeight();
                var blogDescriptionHeight = $(this).find('.description').outerHeight();

                $('.blog-item-homepage figure').height(blogItemHeight - blogDescriptionHeight);
            });

        }

        $('.owl-text-wrapper').matchHeight();
        $('.owl-text-wrapper .owl-button').matchHeight();

        // $('.owl-text-wrapper .owl-description').matchHeight(
        //     {
        //         byRow: true,
        //         target: $('.owl-text-wrapper .owl-button'),
        //         remove: false
        //     }
        // );
    });
}

function scrollHandler() {
    var element = $('.scrollview');

    $(element).each(function() {
        var inview = $(this).visible(true);
        // $(this).toggleClass('inview',inview);
        $(this).toggleClass('inview',inview);
    });
}

function handleSidebar() {
    if( $(window).width() <= 750 ) {
        var $holder = $('.container .sidebar').detach();
        $holder = $holder.addClass('mobile-moved');
        $('footer').before($holder);
        $('.mobile-moved').wrap('<div class="container"><div class="row"></div></div>');
    }
}

$(window).load(function() {
    matchHeight();
    // handleSidebar();
});

$(window).resize(function() {
    matchHeight();
    // handleSidebar();
});

$(window).trigger('resize');

$(window).scroll(function() {
    scrollHandler();
});