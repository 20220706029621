/*
 * Dit is de custom_script.js file
 * In dit bestand kunnen custom JS script geplaatst worden
 */
(function($){
	$(function() {

		//notifications
		if($('#notificatieModal').length > 0) {
			var id = $('#notificatieModal').data('cookie-id');

			var cookie = Cookies.get(id);
			if(cookie === undefined) {
				//show modal
				$('#notificatieModal').modal();

				//set the cookie
				Cookies.set(id, 'notifications showed');
			}
		}


		$('.cta-wrapper figcaption').matchHeight();

		if($('.gallery a').length > 3) {
			$('.real-footer').removeClass('real-footer');
			// $('.gallery').css({
			// 	marginBottom: 0
			// });
		}

		$('span[data-translate]').each(function(){
			var key = $(this).data('translate');
			var value = phrases[key] !== undefined ? phrases[key] : key;
			$(this).before(value).remove();
		});

		if($.trim($('.badge').text()) == '') {$('.badge').text(0);}

		$('#container').imagesLoaded().always( function(  ) {
			$(".dot-ellipsis").trigger("update.dot");
		});

		// if(segment_1 != 'store' && segment_2 != 'store'){
		//     $('.btn-primary').click(function(e){
		//         e.preventDefault();
		//
		//         if($(this).attr('href') == '/reserveren'){
		//             localStorage.setItem("Kamer", $('.page-header > h1').text());
		//         }
		//
		//         window.location = $(this).attr('href');
		//     });
		// }

		if(segment_1 == 'reserveren'){
			$('#ddform_40 option[value="'+localStorage.getItem("Kamer")+'"]').attr('selected', true);
		}

		// $('#new_submission.reserveren').submit(function(){
		// 	var kamer = $('#kamer option:selected').text();
		// 	var van = $('#van').val();
		// 	var tot = $('#tot').val();
		// 	var bericht = $('#bericht').val();
		// 	var onderwerp = $('#onderwerp').val();
		//
		// 	bericht = "Onderwerp: "+onderwerp+"\n"+"Kamer: "+kamer+"\n"+ "Van: "+van+"\n"+ "Tot: "+tot+"\n\n\n"+ bericht;
		// 	$('#message').val(bericht);
		// });

		$('[data-toggle="datepicker"]').datepicker();

		//store specifc
		$('#shipping_same_as_billing').change(function() {
			if (this.checked) {
				$('#shipping_details_drawer').slideUp();
			} else {
				$('#shipping_details_drawer').slideDown();
			}
		});
		if ($('#shipping_same_as_billing').is(':checked')) {
			$('#shipping_details_drawer').hide();
		}
		//landingspage, popup open?
		if(segment_1 == 'form-popup') {
			$('.contact-button').trigger('click');
		}
	});
})(jQuery);
